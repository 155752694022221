import { Controller } from 'stimulus';

export default class extends Controller {
	connect() {
		$('.footable').footable();

		$('[role="deleteMailbox"]').on("click", function (e) {
			e.preventDefault();
			$("#deleteMailboxDialog form").attr("action", $(this).attr("href"));
			$('#deleteMailboxDialog').modal('show');
		});

		$('[role="deleteAlias"]').on("click", function (e) {
			e.preventDefault();
			$("#deleteAliasDialog form").attr("action", $(this).attr("href"));
			$('#deleteAliasDialog').modal('show');
		});

		function cleanUpAliasContainer() {
			var num = 0;
			$("#targetContainer").find('.targetrow').each(function () {
				$(this).find('input').each(function () {
					$(this).attr("name", $(this).attr("name").replace(/\d+/, num));
					$(this).attr("id", $(this).attr("id").replace(/\d+/, num));
				});
				$(this).find('label').each(function () {
					$(this).attr("for", $(this).attr("for").replace(/\d+/, num));
				});
				num++;
			});
		}

		$("#addTarget").on("click", function (e) {
			e.preventDefault();

			var template = $("#targetContainer .targetrow:first").clone();
			template.removeAttr("id");
			template.appendTo("#targetContainer");

			template.find('input').each(function () {
				$(this).val("");
			});

			cleanUpAliasContainer();
		});

		$('#targetContainer').on("click", '[role="deleteTarget"]', function (e) {
			e.preventDefault();
			if ($("#targetContainer").find('.targetrow').length > 1) {
				$(this).parent().parent().remove();
				$("#targetContainer").find('.targetrow').each(function () {
					return;
				});
				cleanUpAliasContainer();
			}
		});
	}
}