import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        function addTagForm(collectionHolder, newLinkLi) {

            var prototype = collectionHolder.data('prototype');
            var index = collectionHolder.data('index');
            var newForm = prototype.replace(/__name__/g, index);
            collectionHolder.data('index', index + 1);
            var newFormLi = $('<li class="rowsenty" valign: middle; padding-top: 10px></li>').append(newForm);
            
            newLinkLi.before(newFormLi);
            addTagFormDeleteLink(newFormLi);
            $('[data-rel="chosen"],[rel="chosen"]').chosen({width: "100%"});
        }
        
        function addTagFormDeleteLink(tagFormLi) {
            var removeFormA = $('<div><a href="#" class="btn btn-danger btn-block"><i class="fa fa-minus"></i></a></div>');
            tagFormLi.append(removeFormA);        
            removeFormA.on('click', function(e) {
                e.preventDefault();            
                tagFormLi.remove();
            });
        }

        var addTagLink = $('<div><a href="#" class="add_tag_link btn btn-block btn-success"><i class="fa fa-plus"></i></a></div>');
        var newLinkLi = $('<li></li>').append(addTagLink);
    
        $('ul.rows').append(newLinkLi);
        $('ul.rows').data('index', $('ul.rows').find(':input').length);
    
        $('ul.rows').find('li.rowsenty').each(function() {
            addTagFormDeleteLink($(this));
        });
    
        addTagLink.on('click', function(e) {
            e.preventDefault();
            addTagForm($('ul.rows'), newLinkLi);
        });
    }
}