import { Controller } from 'stimulus';

const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

export default class extends Controller {
    static targets = []
    static values = {
        route: String,
        domain: String,
        product: String,
        step: String,
        csr: String,
        tworoute: String,
        finalroute: String,
    };
    connect() {
        Routing.setRoutingData(routes);
        var wizard = $('form').wizard();

        console.log(this.finalrouteValue);

        if (this.finalrouteValue) {
            wizard.data.finalRoute = this.finalrouteValue;
        } else if(this.routeValue) {
            if (this.domainValue) {
                wizard.data.finalRoute = Routing.generate(this.routeValue, { domain: this.domainValue, product: this.productValue, step: 4});
            } else {
                wizard.data.finalRoute = Routing.generate(this.routeValue, { domain:0, product: this.productValue, step: 4});
            }
        }        

        if (this.tworouteValue) {
            wizard.data.scrStepRoute = this.tworouteValue;
        } else if(this.routeValue) {
            if (this.domainValue) {
                wizard.data.scrStepRoute = Routing.generate(this.routeValue, { domain: this.domainValue, product: this.productValue, step: 2 });
            } else {
                wizard.data.scrStepRoute = Routing.generate(this.routeValue, { domain: 0, product: this.productValue, step: 2 });
            }
        }
        
        wizard.on('finished', function (event, data) {
            $(this).attr("action", wizard.data.finalRoute);
            $(this).trigger('submit');
        });

        if ((this.domainValue || this.csrValue) && wizard.data.scrStepRoute) {
            wizard.on('change', function (event, data) {
                if (data.step <= 1) {
                    event.preventDefault();
                    $(this).attr("action", wizard.data.scrStepRoute);
                    $(this).trigger('submit');
                }
            });
        }

        function cleanupContainer() {
            var num = 0;
            $("#domainContainer").find('.row').each(function () {
                $(this).find('input').each(function () {
                    $(this).attr("name", $(this).attr("name").replace(/\d+/, num));
                    $(this).attr("id", $(this).attr("id").replace(/\d+/, num));
                });
                $(this).find('label').each(function () {
                    $(this).attr("for", $(this).attr("for").replace(/\d+/, num));
                });
                num++;
            });
        }
        
        $("#addTarget").on("click", function (e) {
            e.preventDefault();    
            var template = $("#targetContainer .targetrow:first").clone();
            template.removeAttr("id");			
            template.appendTo("#targetContainer");    
            template.find('input').each(function() {
                $(this).val("");
            });                                    
            cleanupContainer();
        });
    

        $("#targetContainer").on("click", '[role="deleteTarget"]', function (e) {
            e.preventDefault();
            if($("#targetContainer").find('.targetrow').length > 1){
                $(this).parent().parent().remove();
                $("#targetContainer").find('.targetrow').each(function() {
                    return;
                });				
                cleanupContainer();
            }			
        });
    }
}