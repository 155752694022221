var $ = require('jquery');
window.jQuery = $;
window.$ = $;

require('jquery.flot.current');
require('jquery.maskedinput');
require('jquery.placeholder');
require('chosen-js');

/*
require('./js/u2f-api.js');
// require('./js/auth.js');
require('./js/jquery-ui-1.10.3.custom.min.js');
require('./js/jquery.ui.touch-punch.min.js');
require('./js/jquery-migrate-1.2.1.min.js');
require('./js/jquery.autosize.min.js');
require('./js/bootstrap.min.js');
require('./js/bootstrap-datepicker.min.js');
require('./js/bootstrap-timepicker.min.js');
require('./js/bootstrap-wysiwyg.min.js');
require('./js/bootstrap-colorpicker.min.js');
require('./js/jquery.dataTables.min.js');
require('./js/dataTables.bootstrap.min.js');
require('./js/daterangepicker.min.js');
require('./js/wizard.min.js');
require('./js/select2.min.js');
require('./js/core.js');
*/
require('./css/oauth2.scss');

import './bootstrap';