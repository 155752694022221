import { Controller } from '@hotwired/stimulus';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import toastr from "toastr";

export default class extends Controller {
    connect() {
        
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": false,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "10000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }

        var lastId;
        $("#addnewkey").on("click", function () {
            $(this).toggle();
            $("#wenauthnkeyname-group").toggle();
        });
        $("#addapp").on("click", function () {
            $(this).toggle();
            $("#totpreg").toggle();
        });
        $('[role="deleteKey"]').on("click", function (e) {
            e.preventDefault();
            $('[name="_ack_form"]').attr("action", $(this).attr("href"));
            lastId = $(this).parent().attr("id");
            Swal.fire({
                icon: "question",
                title: $(this).attr("data-name") + " löschen?",
                text: "Die Löschung ist endgültig und kann nicht rückgängig gemacht werden.",
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonText: "Ja",
                cancelButtonText: "Nein",
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    $.post($('[name="_ack_form"]').attr("action"), $('[name="_ack_form"]').serialize()).done(function (data) {
                        $('#' + lastId).remove()
                        toastr.success("Der Sicherheitsschlüssel wurde gelöscht.");
                    });
                }
            });
        });
    }
}
