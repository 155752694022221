import { Controller } from 'stimulus';

export default class extends Controller {
	connect() {
        $('[role="migrateServer"]').on("click", function (e) {
            e.preventDefault();
            $("#deleteEntityDialog form").attr("action", $(this).attr("href"));
            $("#deleteEntityDialog").modal('show');
        });
	}
}