import { Controller } from 'stimulus';

export default class extends Controller {
	connect() {
        $('.footable').footable();
        $('[role="deleteMessage"]').on("click", function (e) {
            e.preventDefault();
            $("#deleteMessageDialog form").attr("action", $(this).attr("href"));
            $("#deleteMessageDialog").modal('show');
        });
        $('[role="resendMessage"]').on("click", function (e) {
            e.preventDefault();
            $("#resendMessageDialog form").attr("action", $(this).attr("href"));
            $("#resendMessageDialog").modal('show');
        });
	}
}