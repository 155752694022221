import { Controller } from '@hotwired/stimulus';
import toastr from "toastr";

export default class extends Controller {
    connect() {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "10000",
            "hideDuration": "10000",
            "timeOut": "10000",
            "extendedTimeOut": "10000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
          }
        for (const message of this.element.children) {
            if (message.getAttribute("data-type") == "success") {
                toastr.success(message.textContent)
            }
            if (message.getAttribute("data-type") == "error") {
                toastr.error(message.textContent)
            }
            if (message.getAttribute("data-type") == "warning") {
                toastr.warning(message.textContent)
            }
        }
    }
}