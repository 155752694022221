'use strict';

import { Controller } from '@hotwired/stimulus';
import { startAuthentication, startRegistration } from '@simplewebauthn/browser';

export default class extends Controller {
    static targets = [ "token", "webauthnErrorMessage", "webauthnError"]
    static values = {
        attestationOptionUrl: String,
        attestationUrl: String,
        attestationSuccessRedirectUri: String,
        assertionOptionUrl: String,
        assertionUrl: String,
        keyNameField: String,
        usernameField: String
    };
    initialize() {
        this._dispatchEvent = this._dispatchEvent.bind(this);
        this._getFormData = this._getFormData.bind(this);
        this.fetch = this.fetch.bind(this);
    }
    connect() {
        const options = {
            attestationOptionUrl: this.attestationOptionUrl || "/attestation/options",
            attestationUrl: this.attestationUrl || "/attestation",
            attestationSuccessRedirectUri: this.attestationSuccessRedirectUri || null,
            assertionOptionUrl: this.assertionOptionUrl || "/assertion/options",
            assertionUrl: this.assertionUrl || "/assertion",
            assertionSuccessRedirectUri: this.assertionSuccessRedirectUri || null
            
        };
        this._dispatchEvent('webauthn:connect', { options });
    }
    async assertion(event) {
        event.preventDefault();
        const data = this._getFormData();
        if (!data.hasOwnProperty('username')) {
            data.username = "null";
        }
        this._dispatchEvent('webauthn:assertion:options', { data });
        try {
            const optionsPesponse = await this.fetch('POST', this.assertionOptionUrlValue || '/assertion/options', JSON.stringify(data));
            const optionsPesponseJson = await optionsPesponse.response;        
            const assertionResponse = await startAuthentication(optionsPesponseJson);
            const verificationResponse = await this.fetch('POST', this.assertionUrlValue || '/assertion', JSON.stringify(assertionResponse));
            const verificationJSON = await verificationResponse.response;        
            this._dispatchEvent('webauthn:assertion:response', { response: assertionResponse });        
            if (verificationJSON && verificationJSON.status === 'ok') {
                this._dispatchEvent('webauthn:assertion:success', verificationJSON);
                this.tokenTarget.value = verificationJSON.token
                this.element.submit()
            }
            else {
                this.webauthnErrorTarget.style.display = "block";
                this.webauthnErrorMessageTarget.innerHTML = verificationJSON.errorMessage;
            }
        }
        catch (e) {
            this.webauthnErrorTarget.style.display = "block";
            this.webauthnErrorMessageTarget.innerHTML = e;
        }
    }
    async attestation(event) {
        event.preventDefault();
        const data = this._getFormData();
        this._dispatchEvent('webauthn:creation:options', { data });
        try {            
            const resp = await this.fetch('POST', this.attestationOptionUrlValue || '/attestation/options', JSON.stringify(data));
            const respJson = await resp.response;
            if (respJson.excludeCredentials === undefined) {
                respJson.excludeCredentials = [];
            }        
            const attResp = await startRegistration(respJson);
            this._dispatchEvent('webauthn:creation:response', { response: attResp });
            const verificationResponse = await this.fetch('POST', this.attestationUrlValue || '/attestation', JSON.stringify(attResp));
            const verificationJSON = await verificationResponse.response;       
            if (verificationJSON && verificationJSON.errorMessage === '') {
                this._dispatchEvent('webauthn:creation:success', verificationJSON);
                if (this.attestationSuccessRedirectUriValue) {
                    window.location.replace(this.attestationSuccessRedirectUriValue);
                }
            }
            else {
                this.webauthnErrorTarget.style.display = "block";
                this.webauthnErrorMessageTarget.innerHTML = verificationJSON.errorMessage;
            }
        }
        catch (e) {
            this.webauthnErrorTarget.style.display = "block";
            this.webauthnErrorMessageTarget.innerHTML = e;
        }
    }

    _dispatchEvent(name, payload) {
        this.element.dispatchEvent(new CustomEvent(name, {detail: payload, bubbles: true}));
    }

    fetch (method, url, body) {
        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.open(method, url);
            xhr.responseType = "json";
            xhr.setRequestHeader('Content-Type', 'application/json')
            xhr.onload = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr);
                } else {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send(body);
        });
    }

    _getFormData() {
        let data = new FormData();
        try {
            data = new FormData(this.element);
        }
        catch (e) {
        }
        function removeEmpty(obj) {
            return Object.entries(obj)
                .filter(([_, v]) => (v !== null && v !== ''))
                .reduce(
                    (acc, [k, v]) => ({...acc, [k]: v === Object(v) ? removeEmpty(v) : v}),
                    {}
                );
        }
        return removeEmpty({
            displayName: data.get(this.keyNameFieldValue || 'displayName'),
            username: data.get(this.usernameFieldValue || 'username')
        });
    }
}