import { Controller } from 'stimulus';

export default class extends Controller {
	connect() {
		$('.footable').footable();
        $('[role="deleteTaskBtn"]').click(function(e){			
			$("#deleteTask form").attr("action", $(this).attr("href"));				
            e.preventDefault();
            $('#deleteTask').modal('show');
    	});    	
		$('[role="runTask"]').click(function(e){			
			$("#runTask form").attr("action", $(this).attr("href"));				
            e.preventDefault();
            $('#runTask').modal('show');
    	});
		$('[role="deleteStateBtn"]').click(function(e){			
			$("#deleteState form").attr("action", $(this).attr("href"));				
            e.preventDefault();
            $('#deleteState').modal('show');
    	});
	}
}