import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {

        $('form').wizard().on('finished', function (e, data) {
            $(this).trigger('submit');
        });

        function cleanUPdomainContainer() {
            var num = 0;
            $("#domainContainer").find('.row').each(function () {
                $(this).find('input').each(function () {
                    $(this).attr("name", $(this).attr("name").replace(/\d+/, num));
                    $(this).attr("id", $(this).attr("id").replace(/\d+/, num));
                });
                $(this).find('label').each(function () {
                    $(this).attr("for", $(this).attr("for").replace(/\d+/, num));
                });
                num++;
            });
        }

        $("#addDomain").on("click", function (e) {
            e.preventDefault();

            var template = $("#domainContainer .row:first").clone();
            template.removeAttr("id");
            template.appendTo("#domainContainer");

            template.find('input').each(function () {
                if ($(this).attr("placeholder") == "example.com") {
                    $(this).val("");
                } else {
                    $(this).val("/");
                }
            });

            cleanUPdomainContainer();
        });

        $("#domainContainer").on("click", '[role="deleteRow"]', function (e) {
            e.preventDefault();
            if ($("#domainContainer").find('.row').length > 1) {
                $(this).parent().parent().remove();
                $("#domainContainer").find('.row').each(function () {
                    $(this).attr("id", "firstDomainRow");
                    return;
                });

                cleanUPdomainContainer();
            }
        });
    }
}